import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
  
function Edit({foundAsset}) {
  const [changed, setChanged] = useState(false);
  const [assetEdit] = useState(foundAsset);
  const [modAsset, setModAsset] = useState(foundAsset);
  var [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

useEffect(()=> {
  console.log(changed);
  console.log('Edit Asset ',assetEdit);
  console.log('Mod Asset ',modAsset);
});

const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

function updateAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(modAsset),
  })
      .then((response) => {
          if (!response.ok) throw new Error('something went wrong');
          return response.json();
      })
      .then((data) => {
        window.location.href='/?assetId='+[modAsset.assetId]
      });
}

function deleteAsset(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [assetEdit.id];
  fetch(url, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
  })
      .then((data) => {
        window.location.href='/'
      });
}
  useEffect(() => {
    if (companiesLoaded === false ) {
    const url = baseUrl + 'company/list/';
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log(json)
          setCompanies(json);
          setCompaniesLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
    })

return (
    <div>
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Asset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteAsset}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

        <div>
        <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          
          label="Asset Id" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modAsset.assetId} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            assetId: e.target.value,
            });
            }}
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="customerTitle">
    Customer
  </InputLabel>
        <Select
          native
          required
          label="Customer"
          inputProps={{
            id: 'customerTitle',
          }}
          fullWidth
          onChange={(e) => {
            setChanged(true);
            setModAsset({
              ...modAsset,
            customer: e.target.value,
            });
          }}>
          <option disabled defaultValue={true}>Current: {modAsset.customer}</option>
          {
          companies.map((companies) => {
          return <option  value={companies.company} key={companies.company}>{companies.description}</option>
        })
      }
  </Select>
     </FormControl>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="type" 
          label="Type" 
          variant="outlined" 
          type="text" 
          value={modAsset.type} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            type: e.target.value,
            });
            }}
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="site" 
          label="Site" 
          variant="outlined" 
          type="text" 
          value={modAsset.site} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            site: e.target.value,
            });
            }}
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="make" 
          label="Make" 
          variant="outlined" 
          type="text" 
          value={modAsset.make} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            make: e.target.value,
            });
            }}
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="model" 
          label="Model" 
          variant="outlined" 
          type="text" 
          value={modAsset.model} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            model: e.target.value,
            });
            }}
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="serialNo" 
          label="Serial Number" 
          variant="outlined" 
          type="text" 
          value={modAsset.serialNo} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            serialNo: e.target.value,
            });
            }}
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="location" 
          label="Location" 
          variant="outlined" 
          type="text" 
          value={modAsset.location} 
          onChange={(e) => {
            setChanged(true);
            setModAsset({
               ...modAsset,
            location: e.target.value,
            });
            }}
        />
     </Box>
     <Box
          margin="dense">
       <FormControl fullWidth>
  <InputLabel>Inspection Frequency</InputLabel>
  <Select
    required
    id="inspectionFrequency"
    name="inspectionFrequency"
    label="Inspection Frequency"
    variant="outlined" 
    key={`${Math.floor((Math.random() * 1000))}-min`}
    defaultValue={modAsset.inspectionFrequency} 
    onChange={(e) => {
      setChanged(true);
      setModAsset({
         ...modAsset,
      inspectionFrequency: e.target.value,
      });
      }}
    >
    <MenuItem value="3 Months">3 Months</MenuItem>
    <MenuItem value="6 Months">6 Months</MenuItem>
    <MenuItem value="12 Months">12 Months</MenuItem>
  </Select>
</FormControl>
</Box>
     
     
{changed ? ( <form
                        className="w-full max-w-sm"
                        id="asset"
                        onClick={updateAsset}
                    >
                     <br></br>
                        <div className="mb-2">
                            <Button variant="contained"
                                form="asset"
                            >
                                Save Changes
                            </Button>
                        </div>
                        </form>
                        
                    ) : null
                  }
<br></br>

                     <form
                        className="w-full max-w-sm"
                        id="assetDelete"
                    >
                    <div className="mb-2">
                            <Button variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?assetId='+[modAsset.assetId]
                                }}
                            >
                                Cancel Editing
                            </Button>
                            &nbsp;&nbsp;&nbsp;  
                            { localStorage.getItem('canDeleteAsset') === '1' ? (
                            <Button variant="contained"
                                form="assetDelete"
                                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleClickOpen}
                            >
                                Delete Asset
                            </Button>
          ): null}
                            </div>
                        </form>
        </div>
  </div>
)};
                              
export default Edit