import React, { useEffect, useState }  from "react";
import '../pages/App.css';
import { baseUrl } from "../shared";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead } from "@mui/material";
import moment from "moment";

export function ViewAll() {
  const [assetList, setAssetList] = useState();
  const [assetListLoaded, setAssetListLoaded] = useState(false);

  function showAllAssets() {
    const url = baseUrl + 'displayThemAll/' + localStorage.getItem('bToken');
    fetch(url, {
      method: 'GET',
    })
      .then((res) => {
        return res.json();
    }).then((json) => {
         setAssetList(json);
         setAssetListLoaded(true);
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  useEffect(() => {
    if(!assetListLoaded) {
      showAllAssets()
    }
  }, []);

    return (
<Paper sx={{ width: '100%', overflow: 'hidden' }}>
<TableContainer sx={{ maxHeight: '65vh', width: '95vw' }}>
{assetListLoaded ? (
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense" stickyHeader>
        <TableHead>
          <TableRow>
        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>Latest inspection date</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Next inspection date</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Asset ID</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Site</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Make</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Model</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Serial/Batch No</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Last Inspection Result</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Inspection Comments</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Workshop Comments</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {assetList.map((list, index) => (
            <TableRow key={index} onClick={(e) => { window.location.href='/?assetId='+ list.assetId}} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{list.lastInspectionDate !== null ? moment(list.lastInspectionDate).format('DD-MM-YYYY') : ''}</TableCell>
              <TableCell sx={{color: list.inspectionColour}}>{list.lastInspectionDate !== null ? moment(list.nextInspection).format('DD-MM-YYYY') : ''}</TableCell>
              <TableCell>{list.assetId}</TableCell>
              <TableCell>{list.customer}</TableCell>
              <TableCell>{list.site}</TableCell>
              <TableCell>{list.type}</TableCell>
              <TableCell>{list.make}</TableCell>
              <TableCell>{list.model}</TableCell>
              <TableCell>{list.serialNo}</TableCell>
              <TableCell>{list.location}</TableCell>
              <TableCell>{list.inspectionPassFail}</TableCell>
              <TableCell>{list.inspectionComments}</TableCell>
              <TableCell>{list.workshopComments}</TableCell>
              <TableCell sx={{color: list.statusColour}}>{list.statusCol}</TableCell>
            </TableRow>
          ))}
          </TableBody>
          </Table>
        ):''}
          </TableContainer>
          </Paper>
  )}

export default ViewAll
