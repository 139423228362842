import React, { useState, useEffect } from "react";
import useForm from "./createProcessForm";
import { baseUrl } from '../../shared';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const FORM_ENDPOINT = baseUrl;

const Form = () => {
  const additionalData = {
    sent: new Date().toISOString(),
  };
  var [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(true);
  };

  const { handleSubmit, status, message } = useForm({
    additionalData,
  });
  
    useEffect(() => {
      if (companiesLoaded === false ) {
      const url = baseUrl + 'company/list/';
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setCompanies(json);
            setCompaniesLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
      })

  if (status === "success") {
    return (
      
      <>
      <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open="true"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Success
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {window.location.href='/'}}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
      </>
    );
  }

  if (status === "error") {
    return (
      <>
      <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open="true"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Error
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {window.location.href='/'}}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
    >
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="assetId" 
          name="assetId"
          label="Asset ID" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="customer">
    Customer
  </InputLabel>
        <Select
          native
          required
          name="customer"
          label="Customer"
          inputProps={{
            id: 'customer',
          }}
          fullWidth
          >
          {
          companies.map((companies) => {
          return <option value={companies.company} key={companies.company}>{companies.description}</option>
        })
      }
  </Select>
     </FormControl>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="type" 
          name="type"
          label="Asset Type" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="site" 
          name="site"
          label="Site" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="make" 
          name="make"
          label="Make" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="model" 
          name="model"
          label="Model" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="serialNo" 
          name="serialNo"
          label="Serial Number" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="location" 
          name="location"
          label="Location" 
          variant="outlined" 
          type="text" 
        />
     </Box>
     <Box
          margin="dense">
       <FormControl fullWidth>
  <InputLabel>Inspection Frequency</InputLabel>
  <Select
    required
    id="inspectionFrequency"
    name="inspectionFrequency"
    label="Inspection Frequency"
    variant="outlined"
    >
    <MenuItem value="3 Months">3 Months</MenuItem>
    <MenuItem value="6 Months">6 Months</MenuItem>
    <MenuItem value="12 Months">12 Months</MenuItem>
  </Select>
</FormControl>
</Box>
      {status !== "loading" && (
        <div className="pt-0 mb-3">
          <Button variant="contained"
            type="submit"
          >
            Create Asset
          </Button>
        </div>
      )}
    </form>
  );
};

export default Form;